<template>
  <div class="container">
    <div class="row text-center my-5" v-if="busy">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-6 mx-auto">
        <!--  -->
        <div class="card border-0 shadow-sm" v-if="forms.length === 0">
          <div class="card-body py-4 text-center">
            <i class="fad fa-info-circle fa-5x text-primary mb-2"></i>
            <h3>No forms found.</h3>
            <p>Forms sent to you by your practitioner will show here.</p>
          </div>
        </div>


        <div v-for="f in forms" :key="f.id">
          <div class="row mb-3">
            <div class="col">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <span v-if="!f.client_response_date" class="badge badge-danger badge-top-right">New</span>
                  <div class="row mb-2">
                    <div class="col my-auto">
                      <h5 class="mb-0">{{ f.title }}</h5><small class="mb-0">{{ f.created_at | formatDate }}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <span v-if="f.client_response_date">
                        <small>Sent to practitioner: {{f.client_response_date | formatDate}}</small>
                      </span>
                      <button class="btn btn-sm float-right"
                              :class="f.client_response_date ? 'btn-primary' : 'btn-outline-primary'"
                              @click="sendResponse(f)">
                        <span v-if="f.client_response_date">View Form</span>
                        <span v-else>Fill in Form</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["client"],
    data() {
      return {
        forms: [],
        busy: true,
      };
    },
    methods: {
      fetchClientForms() {
        this.$axios
                .get(process.env.VUE_APP_API_URL + "/client/forms")
                .then(({ data }) => {
                  this.forms = data;
                  this.busy = false;
                });
      },
      sendResponse(form) {
        this.$router.push("/forms/response/" + form.id);
      }
    },
    mounted() {
      this.fetchClientForms();
    },
    filters: {
      formatDate(date) {
        return moment(date).format("LL");
      },
    }
  };
</script>

<style>
</style>
